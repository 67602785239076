import type { ColumnDef, HeaderContext } from '@tanstack/vue-table'
import type { SetupContext } from 'vue'

export const createColumnFooter = (
  column: ColumnDef<any, any>,
  slots: SetupContext['slots'],
  props: HeaderContext<any, any>,
) => {
  const id = props.column.id
  const footer =
    typeof column.footer === 'function' ? column.footer(props) : column.footer
  const slot = slots[`footer-${props.column.id}`] ?? slots.footer

  if (slot) {
    const slotProps = column.meta?.slotProps ?? {}
    return slot({
      // Custom props
      ...slotProps,
      // Default props
      ...props,
      // Aliases
      id,
      value: footer,
      index: props.header.index,
    })
  }

  return footer
}
