import type { RowData, Table, TableFeature } from '@tanstack/vue-table'

// define types for our new feature's custom state
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface VirtualScrollerTableState {
  //
}

// define types for our new feature's table options
export interface VirtualScrollerOptions {
  enableVirtualScroller?: boolean
}

// Define types for our new feature's table APIs
export interface VirtualScrollerInstance {
  getHasVirtualScroller: () => boolean
}

export const VirtualScrollerFeature: TableFeature<any> = {
  // define the new feature's default options
  getDefaultOptions: <TData extends RowData>(): VirtualScrollerOptions => {
    return {
      enableVirtualScroller: false,
    }
  },

  // define the new feature's table instance methods
  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.getHasVirtualScroller = () =>
      table.options.enableVirtualScroller ?? false
  },
}
