import type { TanstackTablePropsWithDefaults } from '@ui/components/TanstackTable'

export const getPadding = (
  padding: TanstackTablePropsWithDefaults['padding'],
  raw: boolean = false,
) => {
  let x, y

  switch (padding) {
    case 'sm': {
      x = 8
      y = 4
      break
    }
    case 'md': {
      x = 16
      y = 10
      break
    }
    case 'lg': {
      x = 24
      y = 14
      break
    }
    default: {
      x = 0
      y = 0
      break
    }
  }

  return { x: raw ? x : x * 2, y: raw ? y : y * 2 }
}
