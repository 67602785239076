import {
  type Column,
  type ColumnSizingState,
  functionalUpdate,
  type Header,
  makeStateUpdater,
  type OnChangeFn,
  passiveEventSupported,
  type RowData,
  type Table,
  type TableFeature,
  type Updater,
} from '@tanstack/vue-table'
import { getPadding } from '../utils'

// define types for our new feature's custom state
export type GapState = 'sm' | 'md' | 'lg' | 'none'

export interface GapTableState {
  gap: GapState
}

// define types for our new feature's table options
export interface GapOptions {
  enableGap?: boolean
  onGapChange?: OnChangeFn<GapState>
}

// Define types for our new feature's table APIs
export interface GapInstance {
  setGap: (updater: Updater<GapState>) => void
  toggleGap: (value?: GapState) => void
}

export const GapFeature: TableFeature<any> = {
  //Use the TableFeature type!!
  // define the new feature's initial state
  getInitialState: (state): GapTableState => {
    return {
      gap: 'md',
      ...state,
    }
  },

  // define the new feature's default options
  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>,
  ): GapOptions => {
    return {
      enableGap: true,
      onGapChange: makeStateUpdater('gap', table),
    } as GapOptions
  },

  // define the new feature's table instance methods
  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setGap = (updater) => {
      const safeUpdater: Updater<GapState> = (old) => {
        const newState = functionalUpdate(updater, old)
        return newState
      }
      return table.options.onGapChange?.(safeUpdater)
    }
  },

  createColumn: <TData extends RowData, TValue>(
    column: Column<TData, TValue>,
    table: Table<TData>,
  ): void => {
    const _getSize = column.getSize
    column.getSize = () => {
      const size = _getSize()

      if (size === undefined) return

      return (
        size +
        (table.options.enableGap ? getPadding(table.getState().gap).x : 0)
      )
    }
  },
  createHeader: <TData extends RowData, TValue>(
    header: Header<TData, TValue>,
    table: Table<TData>,
  ): void => {
    header.getResizeHandler = (_contextDocument) => {
      const column = table.getColumn(header.column.id)
      const canResize = column?.getCanResize()
      const gap = table.options.enableGap
        ? getPadding(table.getState().gap).x
        : 0

      return (e: unknown) => {
        if (!column || !canResize) {
          return
        }

        ;(e as any).persist?.()

        if (isTouchStartEvent(e)) {
          // lets not respond to multiple touches (e.g. 2 or 3 fingers)
          if (e.touches && e.touches.length > 1) {
            return
          }
        }

        const startSize = header.getSize()

        const columnSizingStart: [string, number][] = header
          ? header
              .getLeafHeaders()
              .map((d) => [d.column.id, getSizeWithoutGap(column, gap)])
          : [[column.id, getSizeWithoutGap(column, gap)]]

        const clientX = isTouchStartEvent(e)
          ? Math.round(e.touches[0]!.clientX)
          : (e as MouseEvent).clientX

        const newColumnSizing: ColumnSizingState = {}

        const updateOffset = (
          eventType: 'move' | 'end',
          clientXPos?: number,
        ) => {
          if (typeof clientXPos !== 'number') {
            return
          }

          table.setColumnSizingInfo((old) => {
            const deltaDirection =
              table.options.columnResizeDirection === 'rtl' ? -1 : 1
            const deltaOffset =
              (clientXPos - (old?.startOffset ?? 0)) * deltaDirection
            const deltaPercentage = Math.max(
              deltaOffset / (old?.startSize ?? 0),
              -0.999999,
            )

            old.columnSizingStart.forEach(([columnId, headerSize]) => {
              newColumnSizing[columnId] =
                Math.round(
                  Math.max(headerSize + headerSize * deltaPercentage, 0) * 100,
                ) / 100
            })

            return {
              ...old,
              deltaOffset,
              deltaPercentage,
            }
          })

          if (
            table.options.columnResizeMode === 'onChange' ||
            eventType === 'end'
          ) {
            table.setColumnSizing((old) => ({
              ...old,
              ...newColumnSizing,
            }))
          }
        }

        const onMove = (clientXPos?: number) => updateOffset('move', clientXPos)

        const onEnd = (clientXPos?: number) => {
          updateOffset('end', clientXPos)

          table.setColumnSizingInfo((old) => ({
            ...old,
            isResizingColumn: false,
            startOffset: null,
            startSize: null,
            deltaOffset: null,
            deltaPercentage: null,
            columnSizingStart: [],
          }))
        }

        const contextDocument =
          _contextDocument || typeof document !== 'undefined' ? document : null

        const mouseEvents = {
          moveHandler: (e: MouseEvent) => onMove(e.clientX),
          upHandler: (e: MouseEvent) => {
            contextDocument?.removeEventListener(
              'mousemove',
              mouseEvents.moveHandler,
            )
            contextDocument?.removeEventListener(
              'mouseup',
              mouseEvents.upHandler,
            )
            onEnd(e.clientX)
          },
        }

        const touchEvents = {
          moveHandler: (e: TouchEvent) => {
            if (e.cancelable) {
              e.preventDefault()
              e.stopPropagation()
            }
            onMove(e.touches[0]!.clientX)
            return false
          },
          upHandler: (e: TouchEvent) => {
            contextDocument?.removeEventListener(
              'touchmove',
              touchEvents.moveHandler,
            )
            contextDocument?.removeEventListener(
              'touchend',
              touchEvents.upHandler,
            )
            if (e.cancelable) {
              e.preventDefault()
              e.stopPropagation()
            }
            onEnd(e.touches[0]?.clientX)
          },
        }

        const passiveIfSupported = passiveEventSupported()
          ? { passive: false }
          : false

        if (isTouchStartEvent(e)) {
          contextDocument?.addEventListener(
            'touchmove',
            touchEvents.moveHandler,
            passiveIfSupported,
          )
          contextDocument?.addEventListener(
            'touchend',
            touchEvents.upHandler,
            passiveIfSupported,
          )
        } else {
          contextDocument?.addEventListener(
            'mousemove',
            mouseEvents.moveHandler,
            passiveIfSupported,
          )
          contextDocument?.addEventListener(
            'mouseup',
            mouseEvents.upHandler,
            passiveIfSupported,
          )
        }

        table.setColumnSizingInfo((old) => ({
          ...old,
          startOffset: clientX,
          startSize,
          deltaOffset: 0,
          deltaPercentage: 0,
          columnSizingStart,
          isResizingColumn: column.id,
        }))
      }
    }
  },
}

function isTouchStartEvent(e: unknown): e is TouchEvent {
  return (e as TouchEvent).type === 'touchstart'
}

function getSizeWithoutGap<TData extends RowData>(
  column: Column<TData>,
  gap: number,
) {
  const size = column.getSize()
  return size ? size - gap : 0
}
