import type { SortingState, Updater } from '@tanstack/vue-table'
import type { DataTableEmits, DataTableProps } from '../types'

export const useSort = (
  props: DataTableProps,
  emit: DataTableEmits,
  onSort: () => void = () => {},
) => {
  const enableSorting = computed(() => !!props.sortable)
  const manualSorting = computed(() => props.sortable === 'manual')
  const sort = useVModel(props, 'sort', emit, { passive: true })
  const setSort = (updaterOrValue: Updater<SortingState>) => {
    sort.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(sort.value)
        : updaterOrValue

    onSort()
  }

  return { enableSorting, manualSorting, sort, setSort }
}
