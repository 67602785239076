import { useStorage } from '@vueuse/core'
import type {
  ColumnSizingInfoState,
  ColumnSizingState,
  Updater,
} from '@tanstack/vue-table'
import type { DataTableEmits, DataTableProps } from '../types'

export const useColumnSizing = (
  props: DataTableProps,
  emit: DataTableEmits,
) => {
  const columnSizing = props.persist?.columnSizing
    ? useStorage<ColumnSizingState>(
        `${props.persist.key}:tableState:columnSizing`,
        () => ({}),
      )
    : ref<ColumnSizingState>({})
  const columnSizingInfo = props.persist?.columnSizing
    ? useStorage<ColumnSizingInfoState>(
        `${props.persist.key}:tableState:columnSizingInfo`,
        () => ({}) as ColumnSizingInfoState,
      )
    : ref<ColumnSizingInfoState>({} as ColumnSizingInfoState)

  const setColumnSizing = (updaterOrValue: Updater<ColumnSizingState>) => {
    columnSizing.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(columnSizing.value)
        : updaterOrValue
  }

  const setColumnSizingInfo = (
    updaterOrValue: Updater<ColumnSizingInfoState>,
  ) => {
    columnSizingInfo.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(columnSizingInfo.value)
        : updaterOrValue
  }

  return {
    columnSizing,
    setColumnSizing,
    columnSizingInfo,
    setColumnSizingInfo,
  }
}
