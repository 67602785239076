import type {
  RowData,
  Column,
  Table,
  TableFeature,
  ColumnSizingColumnDef,
} from '@tanstack/vue-table'
import { toValue } from '../utils'

export const DEFAULT_COLUMN_SIZE = 150
export const defaultColumnSizing = {
  size: undefined,
  minSize: 20,
  maxSize: Number.MAX_SAFE_INTEGER,
}

export const ColumnSizingFeature: TableFeature = {
  getDefaultColumnDef: (): ColumnSizingColumnDef => {
    return defaultColumnSizing
  },

  createColumn: <TData extends RowData, TValue>(
    column: Column<TData, TValue>,
    table: Table<TData>,
  ): void => {
    column.getSize = () => {
      const size =
        table.getState().columnSizing[column.id] ??
        column.columnDef.size ??
        defaultColumnSizing.size ??
        (table.getHasVirtualScroller() ? DEFAULT_COLUMN_SIZE : undefined)

      if (size === undefined) return

      const minSize =
        toValue(column.columnDef.minSize) ?? defaultColumnSizing.minSize
      const maxSize =
        toValue(column.columnDef.maxSize) ?? defaultColumnSizing.maxSize

      return Math.min(Math.max(minSize, size), maxSize)
    }
  },
}
