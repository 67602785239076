import TableBody from '../TableBody'
import type { DataTableEmits, DataTableProps } from '../types'

const ROOT_MARGIN = 500

export interface UseInfiniteScrollProps {
  wrapperRef: Ref<HTMLElement | null>
}

export const useInfiniteScroll = (
  props: DataTableProps,
  emit: DataTableEmits,
  { wrapperRef }: UseInfiniteScrollProps,
) => {
  if (!props.infiniteScroll) return

  const fetchNextPage = (wrapperElement: HTMLElement | null) => {
    if (!wrapperElement) return
    const { scrollHeight, scrollTop, clientHeight } = wrapperElement

    if (
      scrollHeight - scrollTop - clientHeight < ROOT_MARGIN &&
      !props.fetching &&
      props.data.length < props.totalRecords
    ) {
      emit('fetchNextPage')
    }
  }

  useScroll(wrapperRef, {
    onScroll: (e) => fetchNextPage(e.target as HTMLElement),
  })

  watch(
    [wrapperRef, () => props.data],
    () => {
      fetchNextPage(wrapperRef.value)
    },
    { immediate: true },
  )
}
