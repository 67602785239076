import type { RowSelectionState, Updater } from '@tanstack/vue-table'
import type { WatchPausableReturn } from '@vueuse/core'
import type { DataTableEmits, DataTableProps } from '../types'

export const useRowSelection = (
  props: DataTableProps,
  emit: DataTableEmits,
) => {
  const _selection = useVModel(props, 'selection', emit)
  const selection = ref<RowSelectionState>({})
  const setSelection = (updaterOrValue: Updater<RowSelectionState>) => {
    selection.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(selection.value)
        : updaterOrValue
  }

  const watchers: WatchPausableReturn[] = []

  const transformLTR = (left: Record<string, any>[]) => {
    const newSelection: RowSelectionState = {}
    for (const item of left ?? []) {
      const idx = props.data.indexOf(item)
      if (idx !== -1) {
        newSelection[idx] = true
      }
    }
    return newSelection
  }
  const transformRTL = (right: RowSelectionState) => {
    return Object.keys(right).map((key) => props.data[Number(key)])
  }

  watchers.push(
    pausableWatch(
      [_selection, () => props.data],
      ([newValue]) => {
        watchers.forEach((w) => w.pause())
        selection.value = transformLTR(newValue)
        watchers.forEach((w) => w.resume())
      },
      { flush: 'sync', immediate: true },
    ),
  )

  watchers.push(
    pausableWatch(
      selection,
      (newValue) => {
        watchers.forEach((w) => w.pause())
        _selection.value = transformRTL(newValue)
        watchers.forEach((w) => w.resume())
      },
      { flush: 'sync' },
    ),
  )

  return {
    selection,
    setSelection,
  }
}
