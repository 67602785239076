import type { PaginationState, Updater } from '@tanstack/vue-table'
import type { DataTableEmits, DataTableProps } from '../types'

export const usePagination = (props: DataTableProps, emit: DataTableEmits) => {
  const pagination = ref<PaginationState>({ pageIndex: 0, pageSize: 25 })
  const tablePagination = computed(() =>
    props.pagination ? pagination.value : undefined,
  )
  const pageCount = computed(() =>
    Math.ceil(props.totalRecords / pagination.value.pageSize),
  )

  watchEffect(() => {
    if (props.pagination) {
      pagination.value = {
        pageIndex: props.pagination.page - 1,
        pageSize: props.pagination.pageSize,
      }
    }
  })

  const setPagination = (updaterOrValue: Updater<PaginationState>) => {
    pagination.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(pagination.value)
        : updaterOrValue

    emit('update:pagination', {
      page: pagination.value.pageIndex + 1,
      pageSize: pagination.value.pageSize,
    })
  }

  return {
    pageCount,
    tablePagination,
    pagination,
    setPagination,
  }
}
