import type { ColumnDef, HeaderContext } from '@tanstack/vue-table'
import type { SetupContext } from 'vue'

export const createColumnHeader = (
  column: ColumnDef<any, any>,
  slots: SetupContext['slots'],
  props: HeaderContext<any, any>,
) => {
  const id = props.column.id
  const header =
    typeof column.header === 'function' ? column.header(props) : column.header
  const slot = slots[`header-${id}`] ?? slots.header

  if (slot) {
    const slotProps = column.meta?.slotProps ?? {}
    return slot({
      // Custom props
      ...slotProps,
      // Default props
      ...props,
      // Aliases
      id,
      value: header,
      index: props.header.index,
    })
  }

  return header
}
