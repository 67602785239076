import type { ColumnSizingInfoState, Table } from '@tanstack/vue-table'

export interface UseColSizesVarsProps {
  table: Table<any>
  columnSizingInfo: Ref<ColumnSizingInfoState>
}

/**
 * Instead of calling `column.getSize()` on every render for every header
 * and especially every data cell (very expensive),
 * we will calculate all column sizes at once at the root table level in a computed
 * and pass the column sizes down as CSS variables to the <table> element.
 */
export const useColSizesVars = ({
  table,
  columnSizingInfo,
}: UseColSizesVarsProps) => {
  const headers = computed(() => table.getFlatHeaders())

  /**
   * Instead of calling `column.getSize()` on every render for every header
   * and especially every data cell (very expensive),
   * we will calculate all column sizes at once at the root table level in a useMemo
   * and pass the column sizes down as CSS variables to the <table> element.
   */
  const getColumnSizeVars = useMemoize(
    () => {
      const colSizes: { [key: string]: number | undefined } = {}

      // If the table has virtual scroller, we need the actual size of the column.
      // Otherwise, only set size if defined in ColumnDef
      for (let i = 0; i < headers.value.length; i++) {
        const header = headers.value[i]!
        colSizes[`--header-${header.id}-size`] = header.getSize()
        colSizes[`--col-${header.column.id}-size`] = header.column.getSize()
      }
      return colSizes
    },
    { getKey: () => JSON.stringify(table.getState().columnSizing) },
  )

  watch([headers, columnSizingInfo], () => {
    getColumnSizeVars.clear()
  })

  return computed(() => getColumnSizeVars())
}
