import type {
  Column,
  ColumnDef,
  RowData,
  TableFeature,
  ColumnPinningPosition,
} from '@tanstack/vue-table'
import { toValue } from '../utils'

export interface ColumnPinningColumnMeta {
  fixed?: ColumnPinningPosition | (() => ColumnPinningPosition)
}

export const ColumnPinningFeature: TableFeature<any> = {
  getDefaultColumnDef: <TData extends RowData>(): Partial<ColumnDef<TData>> => {
    return {
      enablePinning: false,
      meta: {
        fixed: false,
      },
    }
  },

  createColumn: <TData extends RowData, TValue>(
    column: Column<TData, TValue>,
  ): void => {
    const fixed = toValue(column.columnDef.meta?.fixed) ?? false
    if (fixed) column.pin(fixed)
  },
}
