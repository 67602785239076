import type { Ref } from 'vue'
import {
  breakpointsTailwind,
  useElementSize,
  useWindowSize,
} from '@vueuse/core'
import { useSyncHorizontalScrollbar } from './useSyncHorizontalScrollbar'
import type { DataTableProps } from '../types'

export interface UseVirtualScrollbarProps {
  wrapperRef: Ref<HTMLDivElement | null>
  tableRef: Ref<HTMLDivElement | null>
  scrollbarRef: Ref<HTMLDivElement | null>
  height: Ref<DataTableProps['height']>
}

export const useVirtualScrollbar = ({
  wrapperRef,
  tableRef,
  scrollbarRef,
  height,
}: UseVirtualScrollbarProps) => {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const greaterOrEqualLg = breakpoints.greaterOrEqual('lg')
  const { width: windowWidth } = useWindowSize()
  const tableSize = useElementSize(
    computed(() => tableRef.value?.querySelector('tbody')),
  )
  const showVirtualScrollbar = computed(
    () =>
      !height.value &&
      tableSize.width.value > windowWidth.value &&
      greaterOrEqualLg.value,
  )

  if (!height.value) {
    useSyncHorizontalScrollbar(wrapperRef, scrollbarRef)
  }

  return {
    tableSize,
    showVirtualScrollbar,
  }
}
