import type {
  Column,
  ColumnDef,
  RowData,
  TableFeature,
} from '@tanstack/vue-table'
import { toValue } from '../utils'

export interface ColumnVisibilityColumnMeta {
  visible?: boolean | (() => boolean)
}

export const ColumnVisibilityFeature: TableFeature<any> = {
  getDefaultColumnDef: <TData extends RowData>(): Partial<ColumnDef<TData>> => {
    return {
      meta: {
        visible: true,
      },
    }
  },

  createColumn: <TData extends RowData, TValue>(
    column: Column<TData, TValue>,
  ): void => {
    const visible = toValue(column.columnDef.meta?.visible) ?? true
    if (!visible) column.toggleVisibility(false)
  },
}
