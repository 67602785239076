import type { ColumnDef, ColumnDefResolved } from '@tanstack/vue-table'
import {
  createColumnCell,
  createColumnFooter,
  createColumnHeader,
  resolveColumnId,
} from './'
import type { SetupContext } from 'vue'

export const createDynamicColumn = (
  column: ColumnDef<any, any>,
  slots: SetupContext['slots'],
): ColumnDef<any, any> => {
  return {
    ...column,
    id: resolveColumnId(column as ColumnDefResolved<any, any>)!,
    header: (props) => createColumnHeader(column, slots, props),
    footer: (props) => createColumnFooter(column, slots, props),
    cell: (props) => createColumnCell(column, slots, props),
    columns:
      'columns' in column && column.columns
        ? column.columns.map((col) => createDynamicColumn(col, slots))
        : undefined,
  }
}
