import type { CellContext, ColumnDef } from '@tanstack/vue-table'
import type { SetupContext } from 'vue'

export const createColumnCell = (
  column: ColumnDef<any, any>,
  slots: SetupContext['slots'],
  props: CellContext<any, any>,
) => {
  const id = props.column.id
  const item = props.row.original
  const value =
    typeof column.cell === 'function'
      ? column.cell(props)
      : (column.cell ?? props.getValue())
  const slot = slots[`item-${id}`] ?? slots.item

  if (slot) {
    const slotProps = column.meta?.slotProps ?? {}
    return slot({
      // Custom props
      ...slotProps,
      // Default props
      ...props,
      // Aliases
      id,
      value,
      item,
      index: props.row.index,
    })
  }

  return value
}
