import type { ComputedRef, Ref } from 'vue'
import {
  defaultRangeExtractor,
  useVirtualizer,
  useWindowVirtualizer,
  type Virtualizer,
} from '@tanstack/vue-virtual'
import type { Column, Row, Table } from '@tanstack/vue-table'
import type { DataTableProps } from '../types'

export interface UseVirtualScrollProps {
  wrapperRef: Ref<HTMLDivElement | null>
  tableRef: Ref<HTMLDivElement | null>
  rows: ComputedRef<Row<any>[]>
  visibleColumns: ComputedRef<Column<any>[]>
  virtualScroller: Ref<DataTableProps['virtualScroller']>
  height: Ref<DataTableProps['height']>
  table: Table<any>
}

export const useVirtualScroller = ({
  wrapperRef,
  tableRef,
  rows,
  visibleColumns,
  virtualScroller,
  height,
  table,
}: UseVirtualScrollProps) => {
  const virtualColumnsLength = computed(() => visibleColumns.value.length ?? 0)
  const columnVirtualizer = initializeColumnVirtualizer()
  const rowVirtualizer = initializeRowVirtualizer()

  const virtualColumns = computed(() =>
    columnVirtualizer.value?.getVirtualItems(),
  )
  const virtualRows = computed(() => rowVirtualizer.value?.getVirtualItems())

  const hasVirtualRows = computed(() => !!virtualRows.value)
  const hasVirtualColumns = computed(() => !!virtualColumns.value)

  const columnPinning = computed(() => table.getState().columnPinning)
  const pinnedLeftColumnIndexes = computed(
    () => columnPinning.value.left?.map((_, idx) => idx) ?? [],
  )
  const pinnedRightColumnIndexes = computed(
    () =>
      columnPinning.value.right?.map(
        (_, idx, arr) => virtualColumnsLength.value - arr.length + idx,
      ) ?? [],
  )

  const pinnedColumnIndexes = computed(() => [
    ...pinnedLeftColumnIndexes.value,
    ...pinnedRightColumnIndexes.value,
  ])

  const getVirtualPadding = useMemoize(() => {
    if (!virtualColumns.value || virtualColumns.value.length < 1)
      return {
        '--virtual-padding-left': 0,
        '--virtual-padding-right': 0,
        '--virtual-padding-right-display': 'none',
        '--virtual-padding-left-display': 'none',
      }

    // NOTE: The index of the items in the `virtualColumns` array is not the same as the column index (which can be accessed by `virtualColum.index`)
    // To make it easier to understand, I'll call the column index as "real index and the index in the `virtualColumns` array as "virtual index"

    // We must strip out the size of the columns pinned to the left and right in order to calculate the overflow correctly

    // First virtual column
    const firstVirtualColumn = virtualColumns.value[0]
    // The index of the last pinned virtual column on the left
    const lastPinnedLeftVirtualIndex = pinnedLeftColumnIndexes.value.length - 1
    // The last pinned virtual column on the left
    const lastPinnedLeftVirtualColumn =
      virtualColumns.value[lastPinnedLeftVirtualIndex]
    // The first non-pinned virtual column on the left
    const firstNonPinnedLeftVirtualColumn =
      virtualColumns.value[lastPinnedLeftVirtualIndex + 1]

    let virtualPaddingLeft: number | undefined

    // If there is a gap between the last pinned virtual column on the left and the first non-pinned virtual column
    if (
      lastPinnedLeftVirtualColumn &&
      firstNonPinnedLeftVirtualColumn &&
      lastPinnedLeftVirtualIndex !== firstNonPinnedLeftVirtualColumn.index - 1
    ) {
      // Calculate the overflow stripping out the size of the columns pinned to the left
      virtualPaddingLeft =
        firstNonPinnedLeftVirtualColumn.start - lastPinnedLeftVirtualColumn.end
    } else {
      // Otherwise, calculate the overflow based on the first virtual column as we would normally do
      virtualPaddingLeft = firstVirtualColumn?.start ?? 0
    }

    // Last virtual column
    const lastVirtualColumn =
      virtualColumns.value[virtualColumns.value.length - 1]
    // The index of the first pinned virtual column on the right
    const firstPinnedRightVirtualIndex =
      virtualColumns.value.length - pinnedRightColumnIndexes.value.length
    // The real index of the first pinned column on the right
    const firstPinnedRightRealIndex = pinnedRightColumnIndexes.value[0]
    // The last non-pinned virtual column on the right
    const lastNonPinnedRightVirtualColumn =
      virtualColumns.value[firstPinnedRightVirtualIndex - 1]

    let virtualPaddingRight: number | undefined

    // If there is a gap between the last non-pinned virtual column on the right and the first pinned virtual column
    if (
      lastNonPinnedRightVirtualColumn &&
      firstPinnedRightRealIndex !== lastNonPinnedRightVirtualColumn.index + 1
    ) {
      // Calculate the remaining overflow stripping out the size of the columns pinned to the right
      virtualPaddingRight =
        (columnVirtualizer.value!.getTotalSize() ?? 0) -
        lastNonPinnedRightVirtualColumn.end
    } else {
      // Otherwise, calculate the remaining overflow based on the last virtual column as we would normally do
      virtualPaddingRight =
        (columnVirtualizer.value!.getTotalSize() ?? 0) -
        (lastVirtualColumn?.end ?? 0)
    }

    return {
      '--virtual-padding-left': virtualPaddingLeft,
      '--virtual-padding-right': virtualPaddingRight,
      '--virtual-padding-right-display': virtualPaddingRight ? 'flex' : 'none',
      '--virtual-padding-left-display': virtualPaddingLeft ? 'flex' : 'none',
    }
  })

  watch(virtualColumns, () => {
    getVirtualPadding.clear()
  })

  //different virtualization strategy for columns - instead of absolute and translateY, we add empty columns to the left and right
  const virtualPadding = computed(() => getVirtualPadding())

  return {
    columnVirtualizer,
    rowVirtualizer,
    virtualColumns,
    virtualRows,
    virtualPadding,
    hasVirtualColumns,
    hasVirtualRows,
  }

  function initializeColumnVirtualizer():
    | Ref<Virtualizer<HTMLDivElement, Element>>
    | Ref<undefined> {
    if (!virtualScroller.value) return ref(undefined)

    //we are using a slightly different virtualization strategy for columns (compared to virtual rows) in order to support dynamic row heights
    return useVirtualizer(
      computed(() => ({
        count: virtualColumnsLength.value,
        estimateSize: (index) => visibleColumns.value[index].getSize()!, //estimate width of each column for accurate scrollbar dragging
        getScrollElement: () => wrapperRef.value,
        horizontal: true,
        overscan: virtualScroller.value!.columnsOverscan ?? 5, //how many columns to render on each side off screen each way (adjust this for performance)
        getItemKey: (index) => visibleColumns.value[index].id,
        rangeExtractor: (range) => {
          const next = new Set([
            ...pinnedColumnIndexes.value,
            ...defaultRangeExtractor(range),
          ])

          return [...next].sort((a, b) => a - b)
        },
      })),
    )
  }

  function initializeRowVirtualizer():
    | Ref<Virtualizer<Window, Element>>
    | Ref<Virtualizer<HTMLDivElement, Element>>
    | Ref<undefined> {
    if (!virtualScroller.value) return ref(undefined)

    if (height.value) {
      return useVirtualizer(
        computed(() => ({
          count: rows.value.length,
          estimateSize: (index) => virtualScroller.value!.estimateSize(index), //estimate row height for accurate scrollbar dragging
          getScrollElement: () => wrapperRef.value,
          overscan: virtualScroller.value!.rowsOverscan ?? 5,
        })),
      )
    }

    return useWindowVirtualizer(
      computed(() => ({
        count: rows.value.length,
        estimateSize: (index) => virtualScroller.value!.estimateSize(index), //estimate row height for accurate scrollbar dragging
        scrollMargin: tableRef.value?.offsetTop ?? 0,
        overscan: virtualScroller.value!.rowsOverscan ?? 5,
      })),
    )
  }
}
