import {
  createDynamicColumn,
  createSelectionColumn,
  createExpanderColumn,
} from '../utils'
import type { DataTableProps } from '../types'

export const useInitializeColumns = (props: DataTableProps) => {
  const slots = useSlots()

  const selectionColumn = createSelectionColumn()
  const expanderColumn = createExpanderColumn()
  const dynamicColumns = computed(() =>
    props.columns.map((column) => createDynamicColumn(column, slots)),
  )
  const hasSelection = computed(() => !!props.selection)
  const isExpandable = computed(() => !!props.expandable)

  return computed(() => {
    const columns = [...dynamicColumns.value]

    if (isExpandable.value) {
      columns.unshift(expanderColumn)
    }

    if (hasSelection.value) {
      columns.unshift(selectionColumn)
    }

    return columns
  })
}
