import { memoizeTv } from '~/utils/memoize'
import type { DataTableProps } from '../types'
import { baseTanstackTable } from '../style'

type VariantProps = Parameters<typeof baseTanstackTable>[0]
export type DataTableVariants = ReturnType<typeof baseTanstackTable>

export const __useDataTableVariants = (
  props: DataTableProps,
  variants: VariantProps,
) => {
  return memoizeTv(baseTanstackTable(variants))
  // if (!props.variant) return baseTanstackTable(variants)
  // return props.variant.tv(mergeProps(variants, props.variant.props ?? {}))
}

function mergeProps(obj1: VariantProps, obj2: VariantProps) {
  // Create a new object with the prototype of the first object
  const result = Object.create(Object.getPrototypeOf(obj1))

  // Get property descriptors of the first object
  const descriptors1 = Object.getOwnPropertyDescriptors(obj1)

  // Define these properties on the result object
  Object.defineProperties(result, descriptors1)

  // Get property descriptors of the second object
  const descriptors2 = Object.getOwnPropertyDescriptors(obj2)

  // Define these properties on the result object
  Object.defineProperties(result, descriptors2)

  return result
}
