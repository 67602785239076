import { defaultWindow } from '@vueuse/core'
import type { DataTableProps } from '../types'

export const useStickyHeader = (
  props: DataTableProps,
  tableRef: Ref<HTMLElement | null>,
) => {
  const { offsetHeader: _offsetHeader = 0 } =
    typeof props.sticky === 'object' ? props.sticky : {}
  const isSticky = computed(() => !!props.sticky)
  const theadRef = computed(() => tableRef.value?.querySelector('thead'))
  const offsetHeader = computed(() =>
    _offsetHeader !== undefined && props.height
      ? `${_offsetHeader}px`
      : undefined,
  )

  if (isSticky.value && !props.height) {
    const onScroll = (event: Event) => {
      if (!window || !tableRef.value || !theadRef.value) return

      const el: Element = ((event.target as Window)?.document
        ?.documentElement ||
        (event.target as Document)?.documentElement ||
        unrefElement(event.target as HTMLElement | SVGElement)) as Element

      const header = theadRef.value
      const table = tableRef.value

      const headerTop = table.offsetTop

      let scrollTop = el.scrollTop
      if (event.target === window.document && !scrollTop)
        scrollTop = window.document.body.scrollTop

      if (scrollTop > headerTop) {
        const yTranslation =
          Math.floor(Math.abs(scrollTop - headerTop)) + _offsetHeader
        header.style.setProperty('transform', `translateY(${yTranslation}px)`)
      } else {
        header.style.removeProperty('transform')
      }
    }
    useEventListener(defaultWindow, 'scroll', onScroll, {
      capture: false,
      passive: true,
    })
  }

  return { isSticky, offsetHeader }
}
