import type { ComputedRef } from 'vue'
import type { DataTableProps } from '../types'

export const useResizable = (
  props: DataTableProps,
  { hasVirtualScroller }: { hasVirtualScroller: ComputedRef<boolean> },
) => {
  const isResizable = computed(
    () =>
      props.resizable &&
      (hasVirtualScroller.value ||
        props.columns.every((column) => !!column.size)),
  )

  if (process.env.NODE_ENV !== 'production') {
    watch(
      isResizable,
      () => {
        if (isResizable.value !== props.resizable) {
          console.warn(
            "Column resizing has been disabled because some columns don't have 'size' defined.",
          )
        }
      },
      { immediate: true },
    )
  }

  return { isResizable }
}
