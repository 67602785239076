import type { Updater } from '@tanstack/vue-table'
import type { DataTableEmits, DataTableProps } from '../types'
import type { GapState } from '../features'

export const useGap = (props: DataTableProps, emit: DataTableEmits) => {
  const enableGap = computed(() => !!props.padding && props.padding !== 'none')
  const gap = useVModel(props, 'padding', emit, { passive: true })
  const setGap = (updaterOrValue: Updater<GapState>) => {
    gap.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(gap.value)
        : updaterOrValue
  }

  return { enableGap, gap, setGap }
}
